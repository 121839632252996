import React from "react";

const Loading = ({ className, progress }) => {
  return (
    <div
      className={` h-100 ${
        className === "hidden" ? "hidden" : "d-flex"
      } justify-content-center  align-items-center bg-black wrapper `}
    >
      <div
        style={{
          width: "475px",
          height: "120px",
          borderRadius: "100px 40px",
          position: "relative",
          overflow: "hidden",
          border: "2px solid  #ed6a0f",
        }}
      >
        <div
          style={{ width: `${progress}%` }}
          className="progress-bar-anim"
        ></div>
        <div className="showy-content text-white ">
          <strong className="font-showy relative">
            showy <sup className="absolute showy-sup-text">®</sup>
            <div className="all-in-display">All in one display</div>
          </strong>
        </div>
      </div>
    </div>
  );
};

export default Loading;

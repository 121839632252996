import PropTypes from "prop-types";
import { useEffect, useRef } from "react";
import phoneIcon from "assets/img/white-phone-icon.png";
import qrCodeGhost from "assets/img/qr-code-gost.webp";
import qrCodeActive from "assets/img/qr-code-active.webp";
import qrCodeCorners from "assets/img/qr-code-corners.webp";

import gsap from "gsap";

const drCode = require("qrcode");

export default function UrlQrCode({
  url,
  appState,
  showShortUrl,
  label,
  topIcon,
  backgroundColor,
  borderRadius,
  padding,
  showPhoneIcon,
}) {
  const canvasUrlQrCodeRendererRef = useRef(null);
  const shortUrl = url ? url.replace("https://", "") : null;

  useEffect(() => {
    drCode.toCanvas(canvasUrlQrCodeRendererRef.current, url, function (error) {
      if (error) console.error(error);
      console.log("success!", url);
    });
    console.log("appStatesss", appState);
  }, [url]);

  useEffect(() => {
    console.log("teest");

    gsap.to(".qr-code-label > div", {
      scale: 1.01,
      repeat: -1,
      duration: 0.5,
      yoyo: true,
      delay: 4,
    });
  }, []);

  return (
    <>
      {topIcon && (
        <div className="relative">
          <img
            className="qr-code-top-icon relative"
            src={topIcon}
            alt="topIcon"
          />
          {appState !== "HOME_STATE" && (
            <div
              style={{
                backgroundImage: `url(${qrCodeCorners})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                //backgroundPosition: "center",
                height: "60px",
                width: "60px",
                position: "absolute",
                top: "27%",
                left: "44%",
              }}
            >
              <div className="qrcode"></div>
            </div>
          )}

          {/* <img
            className="qr-code-top-icon"
            src={qrCodeGhost}
            alt="qrCodeGhost"
          /> */}
          {/* <img className="qr-code-top-icon" src={qrCodeActive} alt="topIcon" /> */}
        </div>
      )}
      <div
        className="qrcode-container"
        style={{
          backgroundColor: backgroundColor ? backgroundColor : "transparent",
          borderRadius: borderRadius ? borderRadius : 0,
          padding: padding ? padding : 0,
        }}
      >
        <div className="qrcode-url-canvas-container">
          <canvas
            id={"qrcode-url-canvas"}
            className={"col-auto"}
            ref={canvasUrlQrCodeRendererRef}
          />
        </div>

        {showShortUrl === false && <div id="session-url">{shortUrl}</div>}
        {label && (
          <div className="qr-code-label">
            {showPhoneIcon && (
              <img
                src={phoneIcon}
                alt="phoneIcon"
                style={{
                  display: "inline",
                  width: "12px",

                  marginRight: "8px",
                }}
              />
            )}{" "}
            <div>{label}</div>
          </div>
        )}
      </div>
    </>
  );
}

UrlQrCode.propTypes = {
  messageBackgroundColor: PropTypes.string,
  messageColor: PropTypes.string,
  url: PropTypes.string,
};

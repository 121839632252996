import React, { Component } from "react";
import "./Finish.css";
import gsap from "gsap";
import { Howl } from "howler";

export class Finish extends Component {
  playSound() {
    this.sound = new Howl({
      src: ["/fanfare.mp3"],
    });
    this.sound.play();
  }

  componentDidMount() {
    gsap.to(".close-screen", {
      opacity: 0,
    });
    this.playSound();

    gsap.to(".qz-title", {
      scale: 1.02,
      opacity: 1,
      duration: 1.5,
      delay: 0.1,
      ease: "elastic.out(1,0.3)",
    });
    gsap.to(".qz-body", {
      y: 0,
      opacity: 1,
      duration: 1,
      delay: 0.5,
      ease: "power2.out",
    });

    gsap.to(".overlay", {
      opacity: 0,
      duration: 0.3,
      ease: "power2.out",
    });

    setTimeout(() => {
      this.props.resetSession();
    }, 8000);
  }

  render() {
    const { icon, title, body } = this.props;

    return (
      <div className={"qz-finish"}>
        <div className="qz-inner">
          {icon && <img className={"qz-finish-icon"} src={icon} alt={"icon"} />}
          <div
            className={"qz-title"}
            dangerouslySetInnerHTML={{ __html: title ? title : "Thanks" }}
          ></div>
          {body && (
            <div
              className={"qz-body"}
              dangerouslySetInnerHTML={{ __html: body }}
            />
          )}
        </div>
      </div>
    );
  }
}

export default Finish;

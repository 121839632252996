export default class Timer {
  timer = null;
  timeOutDuration = 60;
  onTimeout = null;

  constructor(onTimeout, duration) {
    this.onTimeout = onTimeout;
    this.timeOutDuration = duration || 60;
    this.reset();
  }

  reset = () => {
    this.timer = setTimeout(this.onTimeout, this.timeOutDuration * 1000);
  };

  clear = () => {
    clearTimeout(this.timer);
  };

  restart = () => {
    this.clear();
    this.reset();
  };
}

import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_HOST,
  headers: {'Authorization': `apikey ${process.env.REACT_APP_API_KEY}`, 'content-type': 'application/ld+json'}
});

export default axiosInstance;

export const getDevice = async (deviceUid) => {
  return await fetchUrl('/devices/' + deviceUid, {headers: {Preload: '"/contentWallConfig","/deviceCircle"'}});
};

export const getByIri = async (iri, config = null) => {
  return fetchUrl(iri, config);
};

export const getCollection = async (url) => {
  const response = await fetchUrl(url);
  return response['hydra:member'] || [];
};

export const fetchUrl = async (url, config = null) => {
  try {
    return (await axiosInstance.get(url, config)).data;
  } catch (e) {
    console.log(e);
    return null;
  }
};

export const createActivateAction = async (action) => {
  return (await axiosInstance({
    method: 'POST',
    url: '/activate_actions',
    data: JSON.stringify(action)
  })).data;
};

export async function getItemRelatedImages(item, imagesProperties) {
  const urlSearchParams = new URLSearchParams();
  for (const imgProperty of imagesProperties) {
    if (typeof item[imgProperty] !== 'undefined') {
      urlSearchParams.append('id[]', item[imgProperty]);
    }
  }
  if (Array.from(urlSearchParams).length > 0) {
    const configImages = await getCollection('/images?' + urlSearchParams.toString());
    for (const configImage of configImages) {
      for (const imgProperty of imagesProperties) {
        if (item[imgProperty] === configImage['@id']) {
          item[imgProperty] = configImage;
          break;
        }
      }
    }
  }
  return item;
}

export async function getAnonymousPosts(project, brands, products, productVersions) {
  let url = '/posts?status=published&exists[person]=false&order[createdAt]=DESC&perPage=30&project=' + project;
  if (typeof brands !== 'undefined' && brands !== null && Array.isArray(brands)) {
    for (let brand of brands) {
      url += '&brand[]=' + brand;
    }
  }
  if (Array.isArray(products)) {
    for (let product of products) {
      url += '&product[]=' + product;
    }
  }
  if (Array.isArray(productVersions)) {
    for (let productVersion of productVersions) {
      url += '&productVersion[]=' + productVersion;
    }
  }
  return await getCollection(url);
}

export async function updateWallUrl(deviceUID, wallKey, userId) {
  const {data} = await axios.post(process.env.REACT_APP_SOCKET_IO_HOST + '/update-url', {
    deviceUID,
    key: wallKey,
    userId: userId,
  });
  return data.url || null;
}

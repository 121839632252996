import { useEffect, useRef, useState } from "react";
import "./ContentWall.css";
import SideBar from "./sideBar";
import WallSlider from "./wallSlider";
import NoContentMessage from "./noContentMessage";
import Timer from "../../util/timer";
import WallWebSocket from "../../services/wallWebSocket";
import {
  getAnonymousPosts,
  getByIri,
  getCollection,
  getItemRelatedImages,
  updateWallUrl,
} from "../../services/mainService";
import { createNewStyleElement } from "../../util/utils";
import Invalidated from "./invalidated";

export default function ContentWall({
  device,
  visible,
  onStart,
  resetState,
  resetAppFired,
}) {
  const initCount = 20;
  const [config, setConfig] = useState(null);
  const [carouselItems, setCarouselItems] = useState([]);
  const [index, setIndex] = useState(0);
  const [direction, setDirection] = useState(null);
  const [wallKey, setWallKey] = useState(null);
  const [currentParticipant, setCurrentParticipant] = useState(null);
  const [url, setUrl] = useState(null);
  const [activeUserSession, setActiveUserSession] = useState(false);
  const [invalidated, setInvalidated] = useState(false);
  const [wallTimeOut, setWallTimeOut] = useState(60);
  const [participantPosts, setParticipantPosts] = useState(null);
  const [source, setSource] = useState("new");
  const [brand, setBrand] = useState(null);
  const [model, setModel] = useState(null);
  const [engine, setEngine] = useState(null);
  const eventSource = useRef(null);
  const timerRef = useRef(null);
  const socketRef = useRef(null);
  const userRef = useRef(null);
  const participantPostsRef = useRef(null);
  const indexRef = useRef(null);
  const wallKeyRef = useRef(null);

  useEffect(() => {
    if (resetAppFired === true) {
      resetApp();
      console.log("resetAppFired");
    }
  }, [resetAppFired]);

  useEffect(() => {
    userRef.current = currentParticipant;
  }, [currentParticipant]);
  useEffect(() => {
    wallKeyRef.current = wallKey;
  }, [wallKey]);
  useEffect(() => {
    indexRef.current = index;
  }, [index]);
  useEffect(() => {
    participantPostsRef.current = participantPosts;
  }, [participantPosts]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!device || !device.contentWallConfig) {
          return;
        }

        const project = device.project;
        let config = await getByIri(device?.contentWallConfig);
        const imagesProperties = [
          "wallBackground",
          "wallSideBarBackground",
          "scanStateBackground",
          "stateBackground",
          "wallScanIcon",
          "wallVisitUrlIcon",
          "wallRetrieveContentIcon",
        ];
        config = await getItemRelatedImages(config, imagesProperties);

        setConfig(config);
        if (config.sessionDuration) {
          setWallTimeOut(config.sessionDuration);
        }

        document.body.style.backgroundColor = config
          ? config.wallBackgroundColor
          : "";
        if (config.cssStyle) {
          createNewStyleElement(config.cssStyle, document);
        }

        let items = [];
        /*if (config.itemsSource === 'vehicle') {
          const vehicles = await getCollection(
            `/vehicles?status=published&order[createdAt]=DESC&perPage=30&project=${project}}`
          );
          items = [...items, ...vehicles];
          setCarouselItems(items);
        } else {*/
        if (config.allowAnonymousContentRetrieve === true) {
          const anonymousItems = await getAnonymousPosts(
            project,
            config.brands,
            config.products,
            config.productVersions
          );
          items = [...items, ...anonymousItems];
        }

        const latestParticipationPosts = await getCollection(
          "/posts?status=published&exists[person]=true&order[createdAt]=DESC&perPage=" +
            initCount +
            "&project=" +
            project
        );
        items = [...items, ...latestParticipationPosts];
        setCarouselItems(items);
        // }
      } catch (e) {
        console.log(e);
      }
    };
    fetchData();
  }, [device]);

  const init = async () => {
    resetTimer();
    const key = Math.floor(Math.random() * 8889 + 1000);
    setWallKey(key);
    setUrl(await updateWallUrl(device.deviceUID, key, null));
    setActiveUserSession(false);
    resetTimer();
  };

  useEffect(() => {
    if (!wallKey || !device || timerRef.current) {
      return;
    }
    timerRef.current = new Timer(() => {
      // if (currentParticipant !== null) {

      // }
      resetApp();
    }, wallTimeOut);
  }, [/*currentParticipant, */ wallKey, device, wallTimeOut]);

  const resetApp = () => {
    socketRef.current.emit("session/expired", {
      deviceUID: device.deviceUID,
      key: wallKeyRef.current,
    });
    resetState();
    resetToInitialState();
    init();
  };

  useEffect(() => {
    if (!device) {
      return;
    }
    socketRef.current = new WallWebSocket(
      device.deviceUID,
      (payload) => {
        if (Number(payload.key) === Number(wallKeyRef.current)) {
          resetTimer();
          let currentIndex = indexRef.current;
          if (payload.direction === "right") {
            currentIndex++;
            if (currentIndex > participantPostsRef.current.length - 1) {
              currentIndex = 0;
            }
            handleParticipantCarouselSelect(currentIndex, "next");
          } else if (payload.direction === "left") {
            currentIndex--;
            if (currentIndex < 0) {
              currentIndex = participantPostsRef.current.length - 1;
            }
            handleParticipantCarouselSelect(currentIndex, "prev");
          }
          setIndex(currentIndex);
        }
      },
      async (payload) => {
        setSource(payload.source || null);
        setBrand(payload.brand || null);
        setModel(payload.model || null);
        setEngine(payload.engine || null);
      },
      (payload) => {
        if (device.deviceUID === payload.deviceUID) {
          socketRef.current.disconnect();
          setInvalidated(true);
          if (eventSource.current) {
            eventSource.current.close();
          }
        }
      },
      () => {
        window.location.reload();
      },
      (payload) => {
        console.log("on url taken", payload);
        if (
          Number(payload.key) === wallKeyRef.current
          /*&&
          userRef.current &&
          parseInt(userRef.current.id) === parseInt(payload.participantId)*/
        ) {
          resetTimer();
          setUrl(null);
          setActiveUserSession(true);
          onStart();
        }
      },
      () => {
        console.log("disconnected");
      }
    );
  }, [device]);

  useEffect(() => {
    if (/*!currentParticipant ||*/ !wallKey || !activeUserSession || !device) {
      return;
    }
    const fetchData = async () => {
      try {
        let items = [];
        const project = device.project;
        /*if (source === 'used') {
          items = await getCollection(
            `/vehicles?status=published&person=/people/${
              currentParticipant.id
            }&project=${project}${brand ? '&brand=' + brand : ''}${
              model ? '&model=' + model : ''
            }${engine ? '&engine=' + engine : ''}`
          );
        } else {*/
        /*items = await getCollection(
            '/posts?status=published&person=/people/' +
              currentParticipant.id +
              '&project=' +
              project
          );*/
        // }

        if (config.allowAnonymousContentRetrieve === true) {
          let anonymousItems;
          /*if (source === 'used') {
            anonymousItems = await getCollection(
              `/vehicles?status=published&order[createdAt]=DESC&perPage=30&project=${project}${
                brand ? '&brand=' + brand : ''
              }${model ? '&model=' + model : ''}${
                engine ? '&engine=' + engine : ''
              }`
            );
          } else {*/
          anonymousItems = await getAnonymousPosts(
            project,
            config.brands,
            config.products,
            config.productVersions
          );
          // }
          items = [...items, ...anonymousItems];
        }

        resetTimer();
        setIndex(0);
        setDirection("prev");
        setParticipantPosts(items);
        console.log("sending ", {
          deviceUID: device.deviceUID,
          post: items.length > 0 ? items[0]["@id"] : null,
        });
        sendCurrentPost({
          deviceUID: device.deviceUID,
          post: items.length > 0 ? items[0]["@id"] : null,
        });
      } catch (e) {
        console.log(e);
      }
    };
    fetchData();
  }, [
    config,
    currentParticipant,
    wallKey,
    activeUserSession,
    device,
    brand,
    model,
    engine,
    source,
  ]);

  //bootstrap content wall system
  useEffect(async () => {
    if (!device) {
      return;
    }
    init();
  }, [device]);

  const resetToInitialState = () => {
    setWallKey(null);
    setActiveUserSession(null);
    setUrl(null);
    setCurrentParticipant(null);
    setParticipantPosts(null);
    setSource(null);
    setBrand(null);
    setModel(null);
    setEngine(null);
  };

  const sendCurrentPost = (payload) => {
    socketRef.current.emit("social/select-post", payload);
  };

  const handleParticipantCarouselSelect = (selectedIndex, direction) => {
    if (
      !participantPostsRef.current ||
      participantPostsRef.current.length === 0
    ) {
      return;
    }
    const payload = {
      deviceUID: device.deviceUID,
      post: participantPostsRef.current[selectedIndex]["@id"],
    };
    sendCurrentPost(payload);
    setIndex(selectedIndex);
    setDirection(direction);
  };

  if (!device.deviceUID || !device) {
    return <p>No Device uid provided</p>;
  }

  let bgUrl =
    config && config.wallBackground ? config.wallBackground.contentUrl : "";
  const scanStateBackground =
    config && config.scanStateBackground
      ? config.scanStateBackground.contentUrl
      : null;
  if (scanStateBackground !== null && currentParticipant === null) {
    bgUrl = scanStateBackground;
  }

  const resetTimer = () => {
    if (timerRef.current) {
      timerRef.current.restart();
    }
  };

  if (invalidated) {
    return <Invalidated />;
  }

  if (!visible) return null;

  console.log("config", config);

  return (
    <div
      className={`content-wall-component state-${url ? "url" : ""}${
        activeUserSession ? "shake-it-get-it" : ""
      } ${!activeUserSession ? "col-2" : ""}`}
      onTouchStart={resetTimer}
      onTouchEnd={resetTimer}
      onMouseMove={resetTimer}
      onClick={resetTimer}
      style={{
        backgroundImage: config?.wallBackground?.contentUrl
          ? `url(${config?.wallBackground?.contentUrl})`
          : "",
      }}
    >
      {/*{config && (
        <div
          className={'poster'}
          style={{ backgroundImage: `url(${bgUrl})` }}
        />
      )}*/}
      {activeUserSession && visible && (
        <div id="container">
          <div className={"main-carousel-container"}>
            {!participantPosts && carouselItems && carouselItems.length > 0 && (
              <WallSlider carouselItems={carouselItems} />
            )}
            {participantPosts && participantPosts.length > 0 && (
              <WallSlider
                carouselItems={participantPosts}
                activeIndex={index}
                direction={direction}
              />
            )}
            {participantPosts && participantPosts.length === 0 && (
              <NoContentMessage />
            )}
          </div>
        </div>
      )}
      {config && visible && (
        <SideBar config={config} shakeItGetIt={activeUserSession} url={url} />
      )}
    </div>
  );
}

import {nl2br} from 'util/utils';
import PropTypes from 'prop-types';

export default function RetrieveContent({message, icon, messageColor, messageBackgroundColor, stateBackground}) {
  return (
    <div style={{textAlign: "center"}}>
      <div id="shaking-phone" className="shake-get-it" style={{backgroundImage: stateBackground ? `url(${stateBackground})` : 'none'}}>
        <div id="mainInteraction">
          <div id="shakeVisual" className="activeVisual">
            <div className="bar">
              {icon &&
              <img src={icon} id="phoneIcon" className="antiSlanted shaky"
                width="120" alt="shake mobile"
              />}
            </div>
          </div>
        </div>
      </div>
      <div style={{color: 'white', textAlign: 'center'}} className={'retrieve-message'}
        dangerouslySetInnerHTML={{__html: nl2br(message)}}/>
      <div className="message" style={{
        color: "white",
        backgroundColor: '#86061f'
      }}>shake It Get It</div>
    </div>
  );
}

RetrieveContent.propTypes = {
  icon: PropTypes.string,
  message: PropTypes.string,
  messageBackgroundColor: PropTypes.string,
  messageColor: PropTypes.string,
  stateBackground: PropTypes.string
};

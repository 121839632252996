import React, { Component } from "react";
import "./index.css";
import axiosInstance from "services/mainService";
// import Identify from "components/quiz/Identify";
import "bootstrap/dist/css/bootstrap.min.css";
// import Scan from "components/quiz/Scan";
import CategoryVideo from "components/quiz/CategoryVideo";
import Intro from "components/quiz/Intro";
import Quiz from "components/quiz/Quiz";
import Finish from "components/quiz/Finish";
import Tutorial from "./Tutorial";
import gsap from "gsap";

// const SCAN_STATE = "scan_state";
const INTRO_STATE = "intro_state";
const QUIZ_STATE = "quiz_state";

export default class QuizContainer extends Component {
  timeOutTimer = null;

  state = {
    deviceUID: window.location.pathname.slice(1)
      ? window.location.pathname.slice(1).split("/").pop()
      : window.localStorage.getItem("deviceUID"),
    device: null,
    quiz: null,
    config: null,
    timeOutDuration: 80000,
    currentParticipant: null,
    quizSession: null,
    showScanMessage: true,
    debugOutput: "",
    currentCategoryIndex: 0,
    currentQuestionIndex: 0,
    showCategoryVideo: false,
    quizFinished: false,
    quizScore: null,
    startQuiz: false,
    category: null,
    appState: INTRO_STATE,
    showTutorial: true,
  };

  constructor(props) {
    super(props);
    this.quizRef = React.createRef();
  }

  resetSessionTimeOut = () => {
    /*if (this.state.currentParticipant === null) {
      return;
    }*/
    clearTimeout(this.timeOutTimer);
    this.setState({ quizFinished: false });
    //TODO: get wallTimeout if not null else use 60000
    this.timeOutTimer = setTimeout(
      () => this.sessionTimeOut(),
      this.state.timeOutDuration
    );
  };

  resetSession = () => {
    clearTimeout(this.timeOutTimer);
    this.setState({
      currentParticipant: null,
      quizSession: null,
      showScanMessage: true,
      currentCategoryIndex: 0,
      currentQuestionIndex: 0,
      showCategoryVideo: false,
      quizFinished: false,
      quizScore: null,
      startQuiz: false,
      appState: INTRO_STATE,
    });
    this.props.resetState();
    //TODO: get wallTimeout if not null else use 60000
    this.timeOutTimer = setTimeout(
      () => this.sessionTimeOut(),
      this.state.timeOutDuration
    );
  };

  /*stopSessionTimeOut = () => {
    if (this.state.currentParticipant === null) {
      return;
    }
    clearTimeout(this.timeOutTimer);
  };*/

  sessionTimeOut = () => {
    this.resetSession();
  };

  getConfig = () => {
    const { device } = this.props;
    if (device) {
      if (device) {
        if (
          typeof device.quizConfig === "undefined" ||
          device.quizConfig === null
        ) {
          return;
        }
        axiosInstance.get(device.quizConfig).then(async ({ data: config }) => {
          if (config) {
            const imagesProperties = [
              "introButtonImage",
              "introBackgroundImage",
              "headerBackgroundImage",
              "scanBackground",
              "choiceIcon",
              "selectedChoiceIcon",
              "incorrectChoiceIcon",
              "correctChoiceIcon",
              "correctAnswerIcon",
              "wrongAnswerIcon",
              "completeIcon",
            ];
            const urlSearchParams = new URLSearchParams();
            for (const imgProperty of imagesProperties) {
              if (
                typeof config[imgProperty] !== "undefined" &&
                config[imgProperty] !== null
              ) {
                urlSearchParams.append("id[]", config[imgProperty]);
              }
            }
            const configImages = (
              await axiosInstance.get("/images?" + urlSearchParams.toString())
            ).data["hydra:member"];
            for (const configImage of configImages) {
              for (const imgProperty of imagesProperties) {
                if (config[imgProperty] === configImage["@id"]) {
                  config[imgProperty] = configImage;
                  break;
                }
              }
            }
            axiosInstance.get(config.quiz).then(async ({ data: quiz }) => {
              const urlSearchParams = new URLSearchParams();
              for (let c of quiz.categories) {
                urlSearchParams.append("id[]", c.video);
              }
              if (
                typeof config.scanVideo !== "undefined" &&
                config.scanVideo !== null
              ) {
                urlSearchParams.append("id[]", config.scanVideo["@id"]);
              }
              const videos = (
                await axiosInstance.get("/videos?" + urlSearchParams.toString())
              ).data["hydra:member"];
              for (const video of videos) {
                for (let c of quiz.categories) {
                  if (c.video === video["@id"]) {
                    c.video = video;
                  }
                }
                if (video["@id"] === config.scanVideo) {
                  config.scanVideo = video;
                }
              }
              this.setState({ quiz });
              // TODO: remove after demo START:
              // setTimeout(() => {
              /*const activateAction = { project: device.project, device: device["@id"] };
                axiosInstance({
                  method: "POST",
                  url: "/activate_actions",
                  data: JSON.stringify(activateAction)
                });*/
              // const { quiz } = this.state;
              const quizSession = {
                quiz: quiz["@id"],
                project: device.project,
                device: device["@id"],
                answers: [],
              };
              for (let c of quiz.categories) {
                c.questions = this.shuffle(c.questions);
              }
              const category = { ...quiz.categories[0] };
              if (config.randomizeQuestionsEnabled === true) {
                category.questions = this.shuffle(category.questions);
                if (config.maxQuestions > 0) {
                  category.questions = category.questions.slice(
                    0,
                    config.maxQuestions
                  );
                }
              }
              this.setState({ category, question: category.questions[0] });

              /*this.setState({
                  quizSession,
                  quizFinished: false,
                  appState: QUIZ_STATE,
                  showScanMessage: false
                });
                this.resetSessionTimeOut();*/
              // }, 3000);

              // TODO: remove after demo END:
            });

            this.setState({ config });
            const head =
                document.head || document.getElementsByTagName("head")[0],
              style = document.createElement("style");
            head.appendChild(style);

            if (style.type) {
              style.type = "text/css";
            }
            if (style.styleSheet) {
              // This is required for IE8 and below.
              style.styleSheet.cssText = config.cssStyle;
            } else {
              style.appendChild(document.createTextNode(config.cssStyle));
            }
          }
        });
      }
    }
  };

  shuffle(array) {
    var currentIndex = array.length,
      randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex],
        array[currentIndex],
      ];
    }

    return array;
  }

  componentDidMount() {
    this.getConfig();

    // document.addEventListener("click", this.resetSessionTimeOut);
  }

  componentDidUpdate() {
    if (false !== this.state.startQuiz) return;

    gsap.to(".tuto > p", {
      y: 0,
      opacity: 1,
      delay: 1,
      ease: "power2.out",
    });
    gsap.to(".tuto > img", {
      x: 0,
      opacity: 1,
      delay: 0.5,
      ease: "power2.out",
    });

    console.log("this.startQuiz");

    // Typical usage (don't forget to compare props):
    //console.log("heey", this.startQuiz);
    // if (this.props.startQuiz !== prevProps.startQuiz) {
    //   gsap.to(".tuto > p", {
    //     y: 0,
    //     opacity: 1,
    //     delay: 1,
    //     ease: "power2.out",
    //   });
    //   gsap.to(".tuto > img", {
    //     x: 0,
    //     opacity: 1,
    //     delay: 0.5,
    //     ease: "power2.out",
    //   });
    //   console.log("updated 2", this.props.startQuiz, prevProps);
    // }
  }

  /*async initMercureHub() {
    const { device, config } = this.state;
    const url = new URL(process.env.REACT_APP_MERCURE_HUB_URL);
    const restartAppTopic = "device/" + device.deviceUID + "/RESTART_APP";
    const NFCReadTopics = [];
    url.searchParams.append("topic", restartAppTopic);
    if (device.deviceCircle != null) {
      if (typeof device.deviceCircle !== "object") {
        try {
          device.deviceCircle = (await axiosInstance.get(device.deviceCircle)).data;
        } catch (e) {
        }
      }
      this.setState({ device: device });
    }
    if (typeof device.deviceCircle !== "undefined" && device.deviceCircle !== null) {
      for (const d of device.deviceCircle.devices) {
        if (typeof d === "object" && d.type.slug === "nfc-activator") {
          const t = "device/" + d.deviceUID + "/NFC_READ";
          NFCReadTopics.push(t);
          url.searchParams.append("topic", t);
        }
      }
    }
    this.eventSource = new EventSource(url);
    this.eventSource.onmessage = async e => {
      console.log(e);
      console.log("mercury event response: ", e.data);
      const payload = JSON.parse(e.data);
      if (NFCReadTopics.indexOf(payload.topic) > -1) {
        if (
          payload.data.participant !== null &&
          typeof payload.data.participant !== "undefined"
        ) {

          const person = payload.data.participant;
          const activateAction = { person: person["@id"], project: device.project, device: device["@id"] };
          axiosInstance({
            method: "POST",
            url: "/activate_actions",
            data: JSON.stringify(activateAction)
          });
          const { quiz } = this.state;
          const quizSession = {
            person: person["@id"],
            quiz: quiz["@id"],
            project: device.project,
            device: device["@id"],
            answers: []
          };
          this.resetSessionTimeOut();
          const category = JSON.parse(JSON.stringify(quiz.categories[0]));
          if (config.randomizeQuestionsEnabled === true) {
            category.questions = this.shuffle(category.questions);
            if (config.maxQuestions > 0) {
              category.questions = category.questions.slice(0, config.maxQuestions);
            }
          }
          this.setState({category, question: category.questions[0]});
          this.setState({
            currentParticipant: person,
            quizSession,
            quizFinished: false,
            appState: INTRO_STATE,
            showScanMessage: false,
            startQuiz: false,
            currentCategoryIndex: 0,
            currentQuestionIndex: 0,
            showCategoryVideo: false
          });
          /!*let { debugOutput } = this.state;
          debugOutput = "new participant activation:<br>" + JSON.stringify(person) + "<br>" + debugOutput;
          this.setState({ debugOutput });*!/
        } else {
          console.log("La carte n'est pas enregistré.");
          let { debugOutput } = this.state;
          debugOutput = "La carte n'est pas enregistré.<br>" + debugOutput;
          this.setState({ debugOutput });
        }
      } else if (payload.topic === restartAppTopic) {
        console.log("received " + restartAppTopic);
        console.log(payload);
        let { debugOutput } = this.state;
        debugOutput = "received " + restartAppTopic + ":<br>" + JSON.stringify(payload) + "<br>" + debugOutput;
        this.setState({ debugOutput });
        window.location.reload();
      } else {
        console.log("unhandled topic");
        let { debugOutput } = this.state;
        debugOutput = "unhandled topic :<br>" + JSON.stringify(payload) + "<br>" + debugOutput;
        this.setState({ debugOutput });
      }
    };
  }*/

  /*showScanMessage() {
    console.log("showScanMessage");
    this.setState({ showScanMessage: true });
    setTimeout(() => {
      this.setState({ showScanMessage: false });
    }, 10000);
  }*/

  nextCategory = async () => {
    const { quiz, currentCategoryIndex } = this.state;
    this.setState({ showCategoryVideo: false });
    this.resetSessionTimeOut();
    if (currentCategoryIndex < quiz.categories.length - 1) {
      const catgeoryIndex = this.state.currentCategoryIndex + 1;
      this.setState({
        currentCategoryIndex: catgeoryIndex,
        currentQuestionIndex: 0,
        category: quiz.categories[catgeoryIndex],
      });
    } else {
      this.setState({ quizFinished: true });
      console.log("quiz finished");
      const { quizSession } = this.state;
      try {
        const quizSessionResponse = (
          await axiosInstance({
            method: "POST",
            url: "/quiz_sessions",
            data: JSON.stringify(quizSession),
          })
        ).data;
        this.setState({ quizScore: parseFloat(quizSessionResponse.score) });
      } catch (e) {}
    }
  };

  answerQuestion = (question, choice, currentQuestionIndex) => {
    console.log(question, choice, currentQuestionIndex);
    const { quizSession } = this.state;
    if (quizSession !== null) {
      quizSession.answers.push({
        question: question["@id"],
        choices: [choice["@id"]],
      });
      this.setState({ quizSession });
    }
  };

  nextQuestion = (currentQuestionIndex) => {
    this.resetSessionTimeOut();
    const { quiz, category } = this.state;
    if (quiz && category.questions.length - 1 === currentQuestionIndex) {
      if (category.video) {
        this.setState({ showCategoryVideo: true });
      } else {
        this.nextCategory();
      }
      //this.stopSessionTimeOut(); note since the button is added no need to stop the timer
    } else {
      const { category } = this.state;
      const questionIndex = this.state.currentQuestionIndex + 1;
      this.setState({
        currentQuestionIndex: questionIndex,
        question: category.questions[questionIndex],
      });
    }
  };

  startQuizSession = () => {
    this.resetSessionTimeOut();
    const { quiz } = this.state;
    const category = { ...quiz.categories[0] };
    this.setState({
      startQuiz: true,
      showTutorial: true,
      appState: QUIZ_STATE,
      currentQuestionIndex: 0,
      question: category.questions[0],
    });
    this.props.onStart();
  };

  answerQuestionViaNfc = (position) => {
    this.quizRef.current.answerQuestionViaNfc(position);
  };

  closeTutorial = () => {
    this.setState({ showTutorial: false });
  };

  render() {
    const {
      config,
      currentQuestionIndex,
      showCategoryVideo,
      quizFinished,
      startQuiz,
      appState,
      category,
      question,
      showTutorial,
    } = this.state;
    const { device, visible } = this.props;

    if (!visible) return null;
    const headerBackgroundImage =
      config && config.headerBackgroundImage
        ? config.headerBackgroundImage.contentUrl
        : null;

    gsap.to(".quiz_state", {
      opacity: 1,
      duration: 1,
      ease: "power2.out",
    });

    return (
      <div
        className={`Quiz ${appState}`}
        style={{
          backgroundImage:
            config && config.introBackgroundImage
              ? "url(" + config.introBackgroundImage.contentUrl + ")"
              : "none",
        }}
        onClick={this.resetSessionTimeOut}
      >
        <div className="quiz-overlay"></div>
        {device && (
          <div className="quiz-container">
            {startQuiz !== true && (
              <Intro
                appState={appState}
                introButtonImage={config ? config.introButtonImage : null}
                introButtonLabel={config ? config.introButtonLabel : null}
                startQuizSession={this.startQuizSession}
              />
            )}
            {showTutorial === true && (
              <Tutorial
                appState={appState}
                closeTutorial={this.closeTutorial}
                startQuiz={startQuiz}
                headerBackgroundImage={headerBackgroundImage}
              />
            )}

            {startQuiz === true && showTutorial !== true && (
              <div>
                {quizFinished === false &&
                  showCategoryVideo === false &&
                  question && (
                    <Quiz
                      ref={this.quizRef}
                      correctAnswerTitle={
                        config ? config.correctAnswerTitle : null
                      }
                      correctAnswerBody={
                        config ? config.correctAnswerBody : null
                      }
                      correctAnswerColor={
                        config ? config.correctAnswerColor : null
                      }
                      choiceIcon={
                        config && config.choiceIcon
                          ? config.choiceIcon.contentUrl
                          : null
                      }
                      selectedChoiceIcon={
                        config && config.selectedChoiceIcon
                          ? config.selectedChoiceIcon.contentUrl
                          : null
                      }
                      incorrectChoiceIcon={
                        config && config.incorrectChoiceIcon
                          ? config.incorrectChoiceIcon.contentUrl
                          : null
                      }
                      correctChoiceIcon={
                        config && config.correctChoiceIcon
                          ? config.correctChoiceIcon.contentUrl
                          : null
                      }
                      correctAnswerIcon={
                        config && config.correctAnswerIcon
                          ? config.correctAnswerIcon.contentUrl
                          : null
                      }
                      wrongAnswerTitle={config ? config.wrongAnswerTitle : null}
                      wrongAnswerBody={config ? config.wrongAnswerBody : null}
                      wrongAnswerColor={config ? config.wrongAnswerColor : null}
                      wrongAnswerIcon={
                        config && config.wrongAnswerIcon
                          ? config.wrongAnswerIcon.contentUrl
                          : null
                      }
                      answerQuestion={this.answerQuestion}
                      nextQuestion={this.nextQuestion}
                      questionGuide={config ? config.questionGuideText : null}
                      headerBackgroundImage={
                        config && config.headerBackgroundImage
                          ? config.headerBackgroundImage.contentUrl
                          : null
                      }
                      category={category}
                      question={question}
                      currentQuestionIndex={currentQuestionIndex}
                    />
                  )}
                {showCategoryVideo && (
                  <CategoryVideo
                    nextCategoryButtonLabel={
                      config ? config.nextCategoryButtonLabel : null
                    }
                    headerBackgroundImage={
                      config && config.headerBackgroundImage
                        ? config.headerBackgroundImage.contentUrl
                        : null
                    }
                    sources={[
                      {
                        source:
                          category && category.video
                            ? category.video.contentUrl
                            : null,
                        type: "video/mp4",
                      },
                    ]}
                    showControls={true}
                    nextCategory={this.nextCategory}
                  />
                )}
                {quizFinished === true &&
                  (showCategoryVideo === false || !category.video) && (
                    <Finish
                      resetSession={this.resetSession}
                      icon={
                        config && config.completeIcon
                          ? config.completeIcon.contentUrl
                          : null
                      }
                      title={config ? config.completeTitle : null}
                      body={config ? config.completeBody : null}
                    />
                  )}
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

import "./Valuator.css";
import UrlQrCode from "components/contentwall/urlQrCode";
import ocarzLogo from "assets/img/ocarz-logo.png";
import greenHand from "assets/img/green-hand-phone.png";
import whatsappIcon from "assets/img/whatsapp.png";
// import valo from 'assets/img/valo.webp';
import { useState } from "react";
import { Howl } from "howler";

export default function Valuator({ url, buttonBackground, appState }) {
  const [expanded, setExpanded] = useState(false);
  const sound = new Howl({
    src: ["/pop-up-off.mp3"],
  });

  const playSound = () => {
    sound.play();
  };
  console.log("appState", appState);
  return (
    <>
      <div id="valo" className={!expanded ? "hidden" : ""}>
        <div className="row">
          <div className="col-auto">
            <img src={ocarzLogo} alt="ocarzLogo" />
          </div>
          <div className="col">
            <div className="welcome">Bienvenue chez Ocarz</div>
            <div className="online">En ligne</div>
          </div>
          <div className="col-auto">
            <button
              className="close"
              onClick={() => {
                setExpanded(!expanded);
              }}
            >
              X
            </button>
          </div>
        </div>
        <div className="qrcode-wrapper">
          <UrlQrCode
            url={url}
            appState={appState}
            label="Scan me!"
            topIcon={greenHand}
            backgroundColor="#62b37c"
            borderRadius="30px"
            padding="20px 10px"
            showPhoneIcon={true}
          />
        </div>
        <p className="valuate">
          Valorisez votre <br />
          véhicule directement <br />
          sur{" "}
          <img
            src={whatsappIcon}
            alt="whatsapp"
            style={{ display: "inline" }}
          />
        </p>
      </div>
      <button
        id="valo-btn"
        className={`${expanded ? "hidden" : ""}`}
        onClick={() => {
          setExpanded(!expanded);
          playSound();
        }}
      >
        <img src={buttonBackground} alt="Ocarz Chatbot" />
      </button>
    </>
  );
}

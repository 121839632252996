const style = {
  position: 'absolute',
  top: '10%',
  left: '10%',
  backgroundColor: 'white',
  fontSize: '30px',
  textAlign: 'center',
};
export default function Invalidated() {
  return (
    <div style={style}>Invalidated, another device has been connected with the same deviceUID.</div>
  );
}


import React, { Component } from "react";
import "./App.css";
import axiosInstance from "./axiosInstance";
import { newTag, lostTag } from "./services/tagDetector.js";
import _ from "lodash";
import ComparableItem from "./components/comparator/ComparableItem";
import "bootstrap/dist/css/bootstrap.min.css";
import ScanMessage from "./components/comparator/ScanMessage";
import ContentWall from "./components/contentwall/ContentWall";
import QuizContainer from "./components/quiz/index";
import UrlQrCode from "./components/contentwall/urlQrCode";
import topIcon from "./assets/img/scan_me_hand.webp";
import Valuator from "./components/Valuator";
import gsap from "gsap";
import { Howl } from "howler";
import { Line, Circle } from "rc-progress";
import Loading from "components/loading/Loading";

var NFCReadChars = [];

const HOME_STATE = "HOME_STATE";
const QUIZ_STATE = "QUIZ_STATE";
const SHAKE_IT_GET_IT_STATE = "SHAKE_IT_GET_IT_STATE";

window.tagDetected = function (json) {
  console.log("received newTag with payload:", json);
  if (json.topic === "NewRfidTagDetected") {
    newTag.next({
      tag_uid: json.payload.tag_id,
      position: json.payload.position,
    });
  } else if (json.topic === "LoseRfidTagDetected") {
    lostTag.next({
      tag_uid: json.payload.tag_id,
      position: json.payload.position,
    });
  }
};

export class App extends Component {
  timeOutTimer = null;

  state = {
    isStandalone: true,
    deviceUID: window.location.pathname.slice(1)
      ? window.location.pathname.slice(1).split("/").pop()
      : window.localStorage.getItem("deviceUID"),
    device: null,
    config: null,
    queueList: {},
    productList: {},
    timeOutDuration: 60000,
    currentParticipant: null,
    showScanMessage: false,
    showSpecialVideo: false,
    appState: HOME_STATE,
    resetShakeItGetItApp: false,
    loadingProgress: 0,
    isLoadingComplete: false,
    quiz: null,
    mediaUrls: [],
    mainVideo: null,
    comparatorItemsVideo: [],
  };

  constructor(props) {
    super(props);
    this.quizRef = React.createRef();
    this.videoRef = React.createRef();
    this.contentWallRef = React.createRef();

    this.sound = new Howl({
      src: ["/pop-up-off.mp3"],
    });
  }

  handlePlaySound() {
    this.sound.play();
  }

  resetTimeOut = () => {
    if (this.state.currentParticipant === null) {
      return;
    }
    clearTimeout(this.timeOutTimer);
    //TODO: get wallTimeout if not null else use 60000
    this.timeOutTimer = setTimeout(
      () => this.timeOut(),
      this.state.timeOutDuration
    );
  };

  timeOut = () => {
    console.log("timeout session expired");
    this.setState({ currentParticipant: null });
  };

  getConfig = () => {
    const { deviceUID } = this.state;
    if (typeof deviceUID !== "undefined" && deviceUID !== null) {
      this.setState({ deviceUID: deviceUID });
    }
    if (deviceUID) {
      axiosInstance.get("/devices/" + deviceUID).then(({ data: device }) => {
        if (device) {
          this.setState({ device });

          if (
            typeof device.comparatorConfig === "undefined" ||
            device.comparatorConfig === null
          ) {
            return;
          }
          axiosInstance
            .get(device.comparatorConfig)
            .then(async ({ data: config }) => {
              if (config) {
                // fetch video
                if (typeof config.mainVideo == "string") {
                  await axiosInstance
                    .get(config.mainVideo)
                    .then(({ data: video }) => {
                      this.setState({
                        config: { ...config, mainVideo: video?.contentUrl },
                      });
                    });
                } else {
                  this.setState({
                    config: {
                      ...config,
                      mainVideo: config.mainVideo?.contentUrl,
                    },
                  });
                }

                const mediaUrls = [
                  this.state.config.backgroundImage?.contentUrl,
                  this.state.config.ocarzChatbotButtonBackground?.contentUrl,
                ];

                this.state.config.items.filter((item) => {
                  Object.values(item).forEach((val) => {
                    if (!val.contentUrl) return;

                    if (val["@id"].startsWith("/videos/")) {
                      mediaUrls.push({
                        url: val?.contentUrl,
                        id: val["@id"],
                      });
                    } else {
                      mediaUrls.push(val?.contentUrl);
                    }
                  });
                });
                const filteredMediaUrls = mediaUrls.filter(
                  (element) => element !== null && element !== undefined
                );

                if (filteredMediaUrls.length > 0) {
                  this.setState({ mediaUrls: filteredMediaUrls });
                }

                this.getQuizConfig();

                if (config.scanRequired === true) {
                  this.initMercureHub();
                }
                this.activateNFCReader();
                const head =
                  document.head || document.getElementsByTagName("head")[0];
                if (config.cssStyle) {
                  const style = document.createElement("style");
                  head.appendChild(style);
                  if (style.type) {
                    style.type = "text/css";
                  }
                  if (style.styleSheet) {
                    // This is required for IE8 and below.
                    style.styleSheet.cssText = config.cssStyle;
                  } else {
                    style.appendChild(document.createTextNode(config.cssStyle));
                  }
                }
                if (config.javascripts) {
                  const scripts = document.createElement("div");
                  scripts.innerHTML = config.javascripts;
                  for (const s of scripts.querySelectorAll("script")) {
                    const script = document.createElement("script");
                    script.setAttribute("src", s.getAttribute("src"));
                    head.appendChild(script);
                  }
                }
              }
            });
        }
      });
    }
  };

  getQuizConfig = () => {
    axiosInstance
      .get(this.state.device?.quizConfig)
      .then(async ({ data: quizz }) => {
        if (quizz) {
          const imagesProperties = [
            "introButtonImage",
            "introBackgroundImage",
            "headerBackgroundImage",
            "scanBackground",
            "choiceIcon",
            "selectedChoiceIcon",
            "incorrectChoiceIcon",
            "correctChoiceIcon",
            "correctAnswerIcon",
            "wrongAnswerIcon",
            "completeIcon",
          ];
          const urlSearchParams = new URLSearchParams();
          for (const imgProperty of imagesProperties) {
            if (
              typeof quizz[imgProperty] !== "undefined" &&
              quizz[imgProperty] !== null
            ) {
              urlSearchParams.append("id[]", quizz[imgProperty]);
            }
          }
          const configImages = (
            await axiosInstance.get("/images?" + urlSearchParams.toString())
          ).data["hydra:member"];

          const quizzUrls = configImages.map((item) => item?.contentUrl);

          if (
            undefined !== this.state.device?.quizConfig &&
            null !== this.state.device?.quizConfig
          )
            this.setState((prevState) => ({
              mediaUrls: [...prevState.mediaUrls, ...quizzUrls],
            }));

          try {
            await this.preloadMedia(this.state.mediaUrls);
            this.setState({ isLoadingComplete: true });
          } catch (error) {
            console.error("Error preloading images:", error);
          }
        }
      });
  };

  preloadMedia = async (urls) => {
    return new Promise(async (resolve, reject) => {
      let loaded = 0;
      urls.length = urls.length + 1;
      const onLoad = () => {
        loaded++;
        const progress = (loaded / urls.length) * 100;
        this.setState({ loadingProgress: progress });

        if (loaded === urls.length) {
          resolve();
        }
      };

      const onError = (error) => {
        reject(error);
      };

      if (this.state.config?.mainVideo) {
        await this.preloadVideo(this.state.config?.mainVideo, onLoad, true);
      }

      urls.forEach(async (url) => {
        if (url?.id) {
          await this.preloadVideo(url, onLoad, false);
        } else {
          const img = new Image();
          img.onload = onLoad;
          img.onerror = onError;
          img.src = url;
        }
      });
    });
  };

  fetchVideo(url, load) {
    fetch(url)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.blob();
      })
      .then((blob) => {
        const videoURL = URL.createObjectURL(blob);
        console.log("videoURL", videoURL);
        this.setState({ mainVideo: videoURL });
        load();
      })
      .catch((error) => {
        console.error("There was a problem with the fetch operation:", error);
      });
  }

  preloadVideo = async (src, load, forMainVideo) => {
    const audioContext = new (window.AudioContext ||
      window.webkitAudioContext)();
    try {
      let response;
      if (forMainVideo) {
        try {
          this.fetchVideo(this.state.config?.mainVideo, load);
          this.fetchVideo(this.state.config?.mainVideo, load);
        } catch (e) {
          console.log("forMainVideo Error:", e);
        }
      } else {
        await fetch(src.url)
          .then((res) => (response = res))
          .then(console.log)
          .catch(console.error);
      }

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const videoBlob = await response.blob();
      const vid = URL.createObjectURL(videoBlob);

      const preloadedVideo = new Audio(vid);

      preloadedVideo.oncanplaythrough = (event) => {
        console.log(
          "I think I can play through the entire video without having to stop to buffer."
        );
        preloadedVideo.muted = true;
        this.setState((prevState) => ({
          comparatorItemsVideo: [
            ...prevState.comparatorItemsVideo,
            { id: src.id, url: preloadedVideo?.src },
          ],
        }));
        audioContext.close();
        load();
      };
    } catch (error) {
      console.error("Error fetching the video:", error.message);
    }
  };

  componentDidMount() {
    this.getConfig();
    newTag.subscribe(
      (payload) => {
        const { config, appState } = this.state;
        if (
          this.state.currentParticipant !== null ||
          (config && config.scanRequired !== true)
        ) {
          this.resetTimeOut();
          if (QUIZ_STATE === appState) {
            this.quizRef.current.answerQuestionViaNfc(payload.position);
          } else {
            if (config.compareBehavior === "drop") {
              this.displayItem(payload.tag_uid, payload.position);
            } else {
              this.hideItem(payload.tag_uid);
            }
          }
        } else {
          this.showScanMessage();
        }
      },
      (error) => {
        console.log("error");
        console.log(error);
      }
    );

    lostTag.subscribe(
      (payload) => {
        const { config, appState } = this.state;
        if (
          this.state.currentParticipant !== null ||
          (config && config.scanRequired !== true)
        ) {
          this.resetTimeOut();
          if (QUIZ_STATE !== appState) {
            if (config.compareBehavior === "drop") {
              this.hideItem(payload.tag_uid);
            } else {
              this.displayItem(payload.tag_uid, payload.position);
            }
          }
        } else {
          this.showScanMessage();
        }
      },
      (error) => {
        console.log("error");
        console.log(error);
      }
    );
  }

  async initMercureHub() {
    const { device } = this.state;
    const url = new URL(process.env.REACT_APP_MERCURE_HUB_URL);
    const restartAppTopic = "device/" + device.deviceUID + "/RESTART_APP";
    const NFCReadTopics = [];
    url.searchParams.append("topic", restartAppTopic);
    if (device.deviceCircle != null) {
      if (typeof device.deviceCircle !== "object") {
        try {
          device.deviceCircle = (
            await axiosInstance.get(device.deviceCircle)
          ).data;
        } catch (e) {}
      }
      this.setState({ device: device });
    }
    if (
      typeof device.deviceCircle !== "undefined" &&
      device.deviceCircle !== null
    ) {
      for (const d of device.deviceCircle.devices) {
        if (typeof d === "object" && d.type.slug === "nfc-activator") {
          const t = "device/" + d.deviceUID + "/NFC_READ";
          NFCReadTopics.push(t);
          url.searchParams.append("topic", t);
        }
      }
    }
    this.eventSource = new EventSource(url);
    this.eventSource.onmessage = async (e) => {
      console.log("mercury event response: ", e.data);
      const payload = JSON.parse(e.data);
      if (NFCReadTopics.indexOf(payload.topic) > -1) {
        if (
          payload.data.participant !== null &&
          typeof payload.data.participant !== "undefined"
        ) {
          const person = payload.data.participant;
          const activateAction = {
            person: person["@id"],
            project: device.project,
            device: device["@id"],
          };
          axiosInstance({
            method: "POST",
            url: "/activate_actions",
            data: JSON.stringify(activateAction),
          });
          console.log("new participant activation");
          this.setState({ currentParticipant: person });
          this.resetTimeOut();
        } else {
          console.log("La carte n'est pas enregistré.");
        }
      } else if (payload.topic === restartAppTopic) {
        console.log("received " + restartAppTopic);
        console.log(payload);
        window.location.reload();
      } else {
        console.log("unhandled topic");
      }
    };
  }

  showScanMessage() {
    console.log("showScanMessage");
    this.setState({ showScanMessage: true });
    setTimeout(() => {
      this.setState({ showScanMessage: false });
    }, 10000);
  }

  activateNFCReader = () => {
    window.document.addEventListener("keyup", this.handleNFCReadKeyUp);
  };

  deactivateNFCReader = () => {
    window.document.removeEventListener("keyup", this.handleNFCReadKeyUp);
  };

  handleNFCReadKeyUp = (event) => {
    const keyCode = "which" in event ? event.which : event.keyCode;
    if (event.keyCode === 13) {
      let numStr = NFCReadChars.join("");
      NFCReadChars = [];
      console.log(numStr);
      const hex = Number(numStr).toString(16);
      //console.log(hex);
      // --------
      const listHex = [];
      const hexStr = hex.toString();
      for (var i = 0; i < hexStr.length; i++) {
        var tmpStr = String(hexStr).substr(i * 2, 2);
        listHex.push(tmpStr);
      }
      //const uidCard = "0x" + listHex.reverse().join("");
      const uidCard = listHex.reverse().join("");
      this.findPersonByUid(uidCard.toUpperCase());
      console.log("uidCard:", uidCard);
    } else {
      NFCReadChars.push(String.fromCharCode(keyCode));
    }
  };

  findPersonByUid = async (uidCard) => {
    try {
      var matches = this.state.device.project.match(/\d+/g);
      const participant = (
        await axiosInstance.get(`/people/${uidCard}/${matches[0]}`)
      ).data;
      if (participant) {
        console.log("new participant activation");
        console.log(participant);
        this.setState({ currentParticipant: participant });
        this.resetTimeOut();
      }
    } catch (e) {
      console.log(e);
    }
  };

  displayItem = (tag, position) => {
    console.log({
      tag,
      envUid: process.env.REACT_APP_PRODUCT_LIST_UID,
      condition: tag === process.env.REACT_APP_PRODUCT_LIST_UID,
    });
    if (tag === process.env.REACT_APP_PRODUCT_LIST_UID) {
      this.setState({ showSpecialVideo: true });
      console.log("should display special video");
      return;
    }
    const { config, currentParticipant, device } = this.state;
    let queueList = { ...this.state.queueList };
    let productList = { ...this.state.productList };
    const product = { ...config.items.find((x) => x.uid === tag), position };
    if (!product) return;
    if ("undefined" === typeof productList[tag]) {
      if (_.size(productList) < config.maxVisibleItems) {
        productList[product.uid] = product;
        this.setState({ productList });
      } else if (!_.has(queueList, product.uid)) {
        queueList[product.uid] = product;
        this.setState({ queueList });
      }
    }

    const compareAction = {
      person: currentParticipant ? currentParticipant["@id"] : null,
      project: device.project,
      device: device["@id"],
      objects: [],
    };
    Object.keys(productList).forEach((key) => {
      compareAction.objects.push(productList[key]["@id"]);
    });
    axiosInstance({
      method: "POST",
      url: "/compare_actions",
      data: JSON.stringify(compareAction),
    });

    this.setState({ productList, queueList });
  };

  hideItem = (tag) => {
    console.log({
      tag,
      envUid: process.env.REACT_APP_PRODUCT_LIST_UID,
      condition: tag === process.env.REACT_APP_PRODUCT_LIST_UID,
    });
    if (tag === process.env.REACT_APP_PRODUCT_LIST_UID) {
      this.setState({ showSpecialVideo: false });
      console.log("should hide special video");
      return;
    }
    let productList = { ...this.state.productList };
    let queueList = { ...this.state.queueList };
    if ("undefined" !== typeof productList[tag]) {
      delete productList[tag];
    }
    if (_.has(queueList, tag)) {
      delete queueList[tag];
    }
    const { config } = this.state;
    if (_.size(queueList) > 0 && _.size(productList) < config.maxVisibleItems) {
      for (const prop in queueList) {
        const product = queueList[prop];
        productList[product.uid] = product;
        delete queueList[product.uid];
        break;
      }
    }
    this.setState({ queueList, productList });
  };

  onQuizStart = () => {
    this.setState({ appState: QUIZ_STATE });
  };

  onShakeItGetItStart = () => {
    this.setState({ appState: SHAKE_IT_GET_IT_STATE });
  };

  resetState = () => {
    this.setState({ appState: HOME_STATE });
  };

  resetShakeItGetState = () => {
    const { appState } = this.state;
    if (SHAKE_IT_GET_IT_STATE === appState) {
      this.setState({ appState: HOME_STATE });
    }
  };

  resetQuizState = () => {
    const { appState } = this.state;
    if (QUIZ_STATE === appState) {
      this.setState({ appState: HOME_STATE });
    }
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (Object.keys(this.state.productList).length > 1) {
      document.querySelector("#root").style.background = "black";
    } else {
      // document.querySelector("#root").style.background =
      //   "url(/static/media/splashscreen.e33ada2f.webp)";
      document.querySelector("#root").style.background = "black";
    }

    if (
      this.state.appState === HOME_STATE &&
      Object.keys(this.state.productList).length === 0 &&
      !this.state.showSpecialVideo
    ) {
      document.querySelector("body").classList.add("show-ashnoo-widget");
    } else {
      document.querySelector("body").classList.remove("show-ashnoo-widget");
    }
  }

  backToHomeState = () => {
    const { appState } = this.state;
    if (SHAKE_IT_GET_IT_STATE === appState) {
      this.setState({ resetShakeItGetItApp: true });
      setTimeout(() => {
        this.setState({ resetShakeItGetItApp: false });
      }, 1000);
    } else if (QUIZ_STATE === appState) {
      this.quizRef.current.resetSession();
    }
    this.setState({ appState: HOME_STATE });
  };

  handleClose = () => {
    gsap.to(".close-screen", {
      scale: 1.1,
      duration: 0.5,
      ease: "elastic.out(1,0.3)",
    });
    gsap.to(".quiz-overlay", {
      left: 0,
      opacity: 1,
      ease: "power2.out",
      duration: 0.5,
    });

    gsap.to(".overlay", {
      right: "auto",
      left: 0,
      opacity: 1,
      ease: "power2.out",
      duration: 0.5,
      onComplete: () => {
        gsap.to(".close-screen", {
          opacity: 0,
          duration: 0.2,
        });
        gsap.to(".quiz-overlay", {
          left: 0,
          ease: "power2.out",
          onComplete: () => {
            this.backToHomeState();
          },
        });
      },
    });

    gsap.to(".tuto > p", {
      y: 20,
      opacity: 0,
    });
    gsap.to(".tuto > img", {
      x: -20,
      opacity: 0,
    });
    gsap.to(".tuto-header > img", {
      scale: 0,
      opacity: 0,
      onComplete: () => {
        gsap.to(".Quiz", {
          opacity: 0,
        });
      },
    });

    gsap.to(".close-tutorial-btn", {
      duration: 2.5,
      ease: "elastic.out(1,0.3)",
      y: -10,
      opacity: 0,
      delay: 0.1,
    });
  };

  render() {
    const {
      appState,
      device,
      config,
      deviceUID,
      productList,
      currentParticipant,
      showScanMessage,
      showSpecialVideo,
      resetShakeItGetItApp,
      mainVideo,
      loadingProgress,
      isLoadingComplete,
      comparatorItemsVideo,
    } = this.state;

    gsap.config({ nullTargetWarn: false });

    let productListArray = Object.values(productList);
    if (config && config.displayType === "by_position") {
      productListArray = productListArray.sort((a, b) =>
        typeof a.position !== "undefined"
          ? a.position > b.position
            ? 1
            : a.position === b.position
            ? a.id > b.id
              ? 1
              : -1
            : -1
          : 1
      );
    }

    const productListCount = Object.keys(productList).length;
    let backgroundImageUrl = "";
    if (productListCount === 1 && config.comparableItemSingleBackgroundImage) {
      backgroundImageUrl =
        config.comparableItemSingleBackgroundImage.contentUrl;
    } else if (productListCount > 1 && config.comparableItemBackgroundImage) {
      backgroundImageUrl = config.comparableItemBackgroundImage.contentUrl;
    }

    return (
      <>
        <Loading
          className={!isLoadingComplete ? "visible" : "hidden"}
          progress={loadingProgress}
        />
        <div
          className={`App`}
          style={{
            visibility: isLoadingComplete ? "visible" : "hidden",
            backgroundImage:
              config &&
              config.backgroundImage &&
              !config.mainVideo &&
              (Object.keys(productList).length === 0 ||
                currentParticipant === null)
                ? "url(" + config.backgroundImage.contentUrl + ")"
                : "",
          }}
        >
          <div className="overlay"></div>
          {deviceUID && config && (
            <div className="wrapper-container">
              {showScanMessage &&
                currentParticipant === null &&
                config &&
                config.scanRequired === true && (
                  <ScanMessage
                    scanRequired={config.scanRequired}
                    scanIcon={config.scanIcon}
                    scanMessage={config.scanMessage}
                  />
                )}
              {appState === HOME_STATE &&
                config &&
                config.mainVideo &&
                productListArray.length === 0 &&
                showSpecialVideo === false && (
                  <video
                    id="mainVideo"
                    // src={
                    //   this.state.config?.mainVideo &&
                    //   this.state.config?.mainVideo
                    // }
                    src={mainVideo}
                    type="video/mp4"
                    style={{ width: "100%", height: "100%" }}
                    autoPlay
                    muted
                    loop
                  >
                    {/* <source src={config.mainVideo} type="video/mp4" /> */}
                  </video>
                )}
              <div className="row no-gutters">
                <div
                  className={`comparator-container ${
                    !showSpecialVideo ? "col-10" : ""
                  }`}
                >
                  {appState === HOME_STATE && (
                    <>
                      {showSpecialVideo ? (
                        <video
                          style={{ width: "100%", height: "100%" }}
                          autoPlay
                          muted
                          loop
                        >
                          <source
                            src={process.env.REACT_APP_PRODUCT_LIST_SRC}
                          />
                        </video>
                      ) : (
                        (currentParticipant !== null ||
                          (config && config.scanRequired !== true)) && (
                          <div
                            className={
                              "row no-gutters items-container  items-" +
                              Object.keys(productList).length
                            }
                            style={{ height: "100%" }}
                          >
                            {productListArray &&
                              productListArray.length > 0 &&
                              productListArray.map((item, index) => {
                                return (
                                  <ComparableItem
                                    comparatorItemsVideo={comparatorItemsVideo}
                                    key={index}
                                    productListArray={productListArray}
                                    index={index}
                                    item={item}
                                    isMultipleItemsState={productListCount > 1}
                                    backgroundImageUrl={backgroundImageUrl}
                                    priceLabel={config.priceLabel}
                                    currency={config.currency}
                                  />
                                );
                              })}
                          </div>
                        )
                      )}
                    </>
                  )}
                </div>
                {device.contentWallConfig && (
                  <ContentWall
                    device={device}
                    visible={
                      (showSpecialVideo === false &&
                        productListArray.length > 0 &&
                        appState !== QUIZ_STATE) ||
                      appState === SHAKE_IT_GET_IT_STATE
                    }
                    onStart={this.onShakeItGetItStart}
                    resetState={this.resetShakeItGetState}
                    resetAppFired={resetShakeItGetItApp}
                  />
                )}
                {config.stickyQrCodeUrl &&
                  !device.contentWallConfig &&
                  productListArray.length > 0 &&
                  appState !== QUIZ_STATE && (
                    <div className="col-2">
                      <div className="stickyQrCode">
                        <UrlQrCode
                          url={config.stickyQrCodeUrl}
                          label="Scan me!"
                          topIcon={topIcon}
                          backgroundColor="#ad0c32"
                          borderRadius="30px"
                          padding="20px 10px"
                          showPhoneIcon={true}
                        />
                      </div>
                    </div>
                  )}
              </div>
              <QuizContainer
                ref={this.quizRef}
                device={device}
                visible={
                  showSpecialVideo === false &&
                  productListArray.length === 0 &&
                  appState !== SHAKE_IT_GET_IT_STATE
                }
                onStart={this.onQuizStart}
                resetState={this.resetQuizState}
              />
            </div>
          )}
          {appState !== HOME_STATE && (
            <button
              className="close-screen"
              onClick={() => {
                this.handlePlaySound();
                this.handleClose();
              }}
            >
              X
            </button>
          )}
          {config &&
            config.ocarzChatbotUrl &&
            appState === HOME_STATE &&
            productListArray.length === 0 && (
              <Valuator
                appState={appState}
                url={config.ocarzChatbotUrl}
                buttonBackground={
                  config.ocarzChatbotButtonBackground
                    ? config.ocarzChatbotButtonBackground.contentUrl
                    : ""
                }
              />
            )}
        </div>
      </>
    );
  }
}

export default App;

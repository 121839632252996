import icon from 'assets/img/no-items-icon.png';
import PropTypes from 'prop-types';

export default function NoContentMessage({message}) {
  return (
    <div style={{width: '100%'}}>
      <div className="no-post">
        {icon && <img src={icon} alt="No content available"/>}
        {message ? <p>{message}</p> : <p>DÉSOLÉ, PAS DE CONTENU DISPONIBLE<br/>
        NOUS VOUS INVITONS À DÉCOUVRIR NOS EXPERIENCES</p>}
      </div>
    </div>
  );
}

NoContentMessage.propTypes = {
  message: PropTypes.string
};

import VisitUrl from "./visitUrl";
import RetrieveContent from "./retrieveContent";
import PropTypes from "prop-types";

export default function SideBar({ url, config, shakeItGetIt }) {
  if (!config) {
    return <div />;
  }

  const {
    wallVisitUrlIcon,
    wallSideBarBackground,
    // wallSideBarBackgroundColor,
    wallRetrieveContentIcon,
    wallVisitUrlColor,
    wallVisitUrlMessage,
    wallRetrieveContentMessage,
    wallVisitUrlBackgroundColor,
    wallRetrieveContentMessageColor,
    wallRetrieveContentMessageBackgroundColor,
  } = config;
  const SideBarStyle = {
    // position: 'absolute',
    // bottom: '0',
    // left: '50%',
    // transform: 'translateX(-50%)',
    // background: 'rgba(0, 0, 0, 0.5) no-repeat',
    backgroundSize: "100%",
    backgroundPosition: "50% 0",
    backgroundImage: null,
    // backgroundColor: "rgba(0,0,0,0.7)",
    borderRadius: "20px",
    padding: "10px",
    opacity: shakeItGetIt === true ? "0" : "1",
    // marginTop: '50vh',
    // transform: 'translateY(-50%)'
  };
  SideBarStyle.backgroundImage = wallSideBarBackground
    ? `url(${wallSideBarBackground.contentUrl})`
    : "";
  // SideBarStyle.backgroundColor = wallSideBarBackgroundColor;
  let content;
  if (url) {
    content = (
      <VisitUrl
        stateBackground={
          config && config.stateBackground
            ? config.stateBackground.contentUrl
            : null
        }
        message={wallVisitUrlMessage}
        icon={wallVisitUrlIcon ? wallVisitUrlIcon.contentUrl : null}
        url={url}
        messageColor={wallVisitUrlColor}
        messageBackgroundColor={wallVisitUrlBackgroundColor}
      />
    );
  } else if (shakeItGetIt) {
    content = (
      <RetrieveContent
        stateBackground={
          config && config.stateBackground
            ? config.stateBackground.contentUrl
            : null
        }
        icon={
          wallRetrieveContentIcon ? wallRetrieveContentIcon.contentUrl : null
        }
        message={wallRetrieveContentMessage}
        messageColor={wallRetrieveContentMessageColor}
        messageBackgroundColor={wallRetrieveContentMessageBackgroundColor}
      />
    );
  }
  return (
    <div className="side-bar" style={SideBarStyle}>
      <div className="wrapper">{content}</div>
    </div>
  );
}

SideBar.propTypes = {
  config: PropTypes.object,
  url: PropTypes.string,
  shakeItGetIt: PropTypes.bool,
};

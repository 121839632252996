import { Subject } from 'rxjs';

export const newTag = new Subject();
export const lostTag = new Subject();

// Create WebSocket connection.
const socket = new WebSocket(process.env.REACT_APP_WS_HOST);

// Connection opened
socket.addEventListener('open', function (event) {
  socket.send('Hello Server!');
});

// Listen for messages
socket.addEventListener('message', function (event) {
  console.log('Message from server ', event.data);
  try {
    const json = JSON.parse(event.data);
    console.log({json});
    if(json.topic === 'NewRfidTagDetected') {
      newTag.next({tag_uid: json.payload.tag_id, position: json.payload.position})
    }
    else if(json.topic === 'LoseRfidTagDetected') {
      lostTag.next({tag_uid: json.payload.tag_id, position: json.payload.position})
    }
  } catch (e) {
    console.log('received:' + event.data);
  }
});

import React, { Component } from "react";
import "./Tutorial.css";
import tuto from "assets/img/tuto_showy_quiz.png";
import quizHeader from "assets/img/quiz-header.webp";
import gsap from "gsap";
import { Howl } from "howler";

export class Tutorial extends Component {
  closeTutorial() {
    this.props.closeTutorial();
    this.sound = new Howl({
      src: ["/pop-up-off.mp3"],
    });
    this.sound.play();
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.startQuiz !== prevProps.startQuiz ||
      "quiz_state" === this.props.appState
    ) {
      gsap.to(".tuto > img", {
        x: 0,
        opacity: 1,
        delay: 0.5,
        ease: "power2.out",
      });
      gsap.to(".tuto > p", {
        y: 0,
        opacity: 1,
        delay: 1,
        ease: "power2.out",
      });
      gsap.to(".tuto-header > img", {
        scale: 1,
        opacity: 1,
        delay: 0.6,
        duration: 1.4,
        ease: "elastic.out(1,0.3)",
      });
      gsap.to(".close-tutorial-btn", {
        duration: 2.5,
        ease: "elastic.out(1,0.3)",
        y: 0,
        opacity: 1,
        delay: 0.8,

        // onComplete: () => {
        //   gsap.to(".close-screen", {
        //     scale: 1,
        //     opacity: 1,
        //     duration: 1,
        //     ease: "elastic.out(1,0.3)",
        //   });
        // },
      });
    }
  }

  // componentDidMount() {

  // }

  render() {
    return (
      <div className={"tutorial"}>
        <header className="tuto-header">
          <img
            src={
              this.props.headerBackgroundImage
                ? this.props.headerBackgroundImage
                : quizHeader
            }
            alt=""
          />
        </header>
        <div className="tuto">
          <p>
            Déplacer les objets 3d devant vous vers <br />
            les emplacements correspondant à chaque réponse
          </p>
          <img src={tuto} alt="tuto" />
        </div>
        <button
          className={"close-tutorial-btn"}
          onClick={() => this.closeTutorial()}
        >
          Commencer
        </button>
      </div>
    );
  }
}

export default Tutorial;

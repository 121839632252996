import React, { Component } from "react";
import "./Quiz.css";
import "bootstrap/dist/css/bootstrap.min.css";
import gsap from "gsap";
import { Howl } from "howler";

const CHOICE_SELECTION_DELAY = 1000;
const CHOICE_CORRECTION_DELAY = 5000;

export class Quiz extends Component {
  state = {
    selectedChoice: null,
    showSelected: false,
    showCorrection: false,
  };

  playSound() {
    this.sound = new Howl({
      src: ["/pop-down.mp3"],
    });
    this.sound.play();
  }

  componentDidMount() {
    gsap.to(".qz-question-text > p", {
      y: 0,
      opacity: 1,
      delay: 0.1,
      ease: "power2.out",
    });
    gsap.to(".qz-choice-button > div > p", {
      y: 0,
      opacity: 1,
      delay: 0.2,
      ease: "power2.out",
    });
    // console.log("foo", this.props.question?.id);
  }
  componentDidUpdate(prevState) {
    // console.log(
    //   "foo prev: foo current",

    //   this.props.question?.id
    // );

    if (this.props.question?.id !== prevState.question?.id) {
      gsap.to(".qz-question-text > p", {
        y: 0,
        opacity: 1,
        delay: 0.1,
        ease: "power2.out",
      });
      gsap.to(".qz-choice-button > div > p", {
        y: 0,
        opacity: 1,
        delay: 0.2,
        ease: "power2.out",
      });
    }
  }

  answer = (question, choice, currentQuestionIndex) => {
    const { selectedChoice } = this.state;
    if (null !== selectedChoice) {
      console.log("question already answered. Ignoring...");
      return;
    }
    this.setState({ selectedChoice: choice, showSelected: true });

    setTimeout(() => {
      this.setState({ showCorrection: true, showSelected: false });
      this.props.answerQuestion(question, choice, currentQuestionIndex);
      setTimeout(() => {
        this.setState({ selectedChoice: null, showCorrection: false });
        this.props.nextQuestion(currentQuestionIndex);
      }, CHOICE_CORRECTION_DELAY);
    }, CHOICE_SELECTION_DELAY);
  };

  answerQuestionViaNfc = (position) => {
    const { question, currentQuestionIndex } = this.props;
    console.log("question.choices[position-1]", question.choices[position - 1]);
    this.answer(question, question.choices[position - 1], currentQuestionIndex);
  };

  render() {
    const {
      question,
      currentQuestionIndex,
      headerBackgroundImage,
      correctChoiceIcon,
      choiceIcon,
      incorrectChoiceIcon,
      selectedChoiceIcon,
      wrongAnswerIcon,
      correctAnswerIcon,
      /*,questionGuide, category,
      correctAnswerIcon, correctAnswerTitle, correctAnswerBody, correctAnswerColor,
      wrongAnswerIcon, wrongAnswerTitle, wrongAnswerBody, wrongAnswerColor*/
    } = this.props;
    console.log({ choiceIcon, incorrectChoiceIcon, selectedChoiceIcon });
    const { selectedChoice, showCorrection /*, showSelected*/ } = this.state;

    return (
      <div className={"quiz"}>
        {headerBackgroundImage && (
          <header className="qz-header">
            <img src={headerBackgroundImage} alt="" />
          </header>
        )}
        <div className="row">
          <div className="col qz-question-container">
            <div className="qz-question-guide">
              {/*<img src={quizHeader} alt="quiz header"/>*/}
              <div
                className="qz-question-text"
                dangerouslySetInnerHTML={{ __html: question.question }}
              ></div>
            </div>
            {/*{(selectedChoice === null || showSelected === true) &&
            <>
              <div className="qz-question-guide">
                <span className="qz-icon"></span>
                {`${questionGuide} ${currentQuestionIndex + 1}`}
              </div>
              <div className="qz-question-text" dangerouslySetInnerHTML={{ __html: question.question }}></div>
            </>
            }
            {selectedChoice !== null && showCorrection &&
            <div className="qz-correction">
              {(correctAnswerIcon || wrongAnswerIcon) &&
              <img src={selectedChoice.correct === true ? correctAnswerIcon : wrongAnswerIcon} alt=""/>
              }
              <div className="qz-title" dangerouslySetInnerHTML={
                { __html: selectedChoice.correct === true ? correctAnswerTitle : wrongAnswerTitle }
              }></div>

              <div className="qz-body" dangerouslySetInnerHTML={
                { __html: selectedChoice.correct === true ? correctAnswerBody : wrongAnswerBody }
              }></div>
            </div>
            }*/}
            <div className="qz-choices-container row align-content-center">
              {question.choices.map((choice, index) => {
                // let className = "";
                let choiceIndicatorImg = choiceIcon;
                if (selectedChoice !== null) {
                  if (showCorrection === true) {
                    if (choice.correct === true) {
                      // className = "qz-correct";
                      choiceIndicatorImg =
                        choice.id === selectedChoice.id
                          ? correctAnswerIcon
                          : correctChoiceIcon;
                    } else if (choice.id === selectedChoice.id) {
                      // className = "qz-wrong";
                      choiceIndicatorImg = wrongAnswerIcon;
                    } else {
                      choiceIndicatorImg = incorrectChoiceIcon;
                    }
                  } else if (selectedChoice.id === choice.id) {
                    // className = "qz-selected";
                    choiceIndicatorImg = selectedChoiceIcon;
                  }
                }
                return (
                  <div className="col" key={index}>
                    <button
                      className={`qz-choice-button `}
                      disabled={selectedChoice !== null}
                      onClick={() => {
                        this.answer(question, choice, currentQuestionIndex);
                        this.playSound();
                      }}
                    >
                      <img src={choiceIndicatorImg} alt="choiceIndicator" />
                      <div
                        dangerouslySetInnerHTML={{ __html: choice.choice }}
                      ></div>
                    </button>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Quiz;

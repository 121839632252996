import Carousel from 'react-bootstrap/Carousel';
import {useRef} from 'react';
import PropTypes from 'prop-types';
import Slide from './slide';

export default function WallSlider({carouselItems, activeIndex, direction}) {
  const carousel = useRef(null);
  let nextButton = null;

  const handleSelect = (selectedIndex, direction) => {
    console.log('direction', direction);
    handleCurrentItem(selectedIndex);
    //setCarouselActiveIndex(selectedIndex);
  };

  const handleCurrentItem = () => {
    nextButton = carousel.current.element.querySelector('.carousel-control-next');
    const mediaElement = carousel.current.element.querySelector('.carousel-inner .carousel-item.active > *');
    if (mediaElement && nextButton) {
      if (mediaElement.nodeName === 'VIDEO') {
        mediaElement.currentTime = 0;
        const playPromise = mediaElement.play();
        if (playPromise !== undefined) {
          playPromise.then(function () {
            // Automatic playback started!
            console.log('video playback started');
          }).catch(function (error) {
            console.log('video playback could not be started ui is needed');
            console.log(error);
          });
        }
        mediaElement.addEventListener('ended', handleVideoFinishedPlaying);
      } else if (mediaElement.nodeName === 'IMG') {
        setTimeout(() => {
          nextButton.click();
        }, 10000);
      }
    }
  };


  const handleVideoFinishedPlaying = (e) => {
    if (e) {
      e.target.pause();
      nextButton.click();
      e.target.removeEventListener('ended', handleVideoFinishedPlaying);
    }
  };

  return (
    <Carousel
      activeIndex={activeIndex}
      direction={direction}
      onSelect={handleSelect}
      fade={true}
      controls={true}
      indicators={false}
      interval={null}
      slide={true}
      ref={carousel}>
      {carouselItems.map(item => {
        const video = item.video || (item.videos && item.videos[0]) || null;
        const photo = item.image || (item.photos && item.photos[0]) || null;
        return (
          <Carousel.Item key={video !== null ? item.id + 'video' : item.id + 'photo'}>
            <Slide title={item.title} video={video} photo={photo} handleVideoFinishedPlaying={handleVideoFinishedPlaying} />
          </Carousel.Item>
        );
      })}
    </Carousel>
  );
}

WallSlider.propTypes = {
  activeIndex: PropTypes.number,
  carouselItems: PropTypes.array,
  direction: PropTypes.string
};

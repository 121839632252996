import { nl2br } from "util/utils";
import UrlQrCode from "./urlQrCode";
import PropTypes from "prop-types";
import topIcon from "../../assets/img/scan_me_hand.webp";

export default function VisitUrl({
  icon,
  message,
  url,
  messageColor,
  stateBackground,
}) {
  return (
    <div
      id="current-url"
      className="step-2 visit-message"
      style={{
        backgroundImage: stateBackground ? `url(${stateBackground})` : "none",
      }}
    >
      {/*<div className="step-2-icon-txt">
        {icon &&
        <img className="step-2-icon" src={icon} alt="icon"/>}
        <span dangerouslySetInnerHTML={{__html: nl2br(message)}}/>
      </div>*/}
      <div>
        <UrlQrCode
          url={url}
          label="Scan me!"
          topIcon={topIcon}
          backgroundColor="#ad0c32"
          borderRadius="30px"
          padding="20px 10px"
          showPhoneIcon={true}
        />
      </div>
      {/* <UrlQrCode url={url} messageColor={messageColor} /> */}
      {/* <p className="scan-me">SCAN ME</p> */}
    </div>
  );
}

VisitUrl.propTypes = {
  icon: PropTypes.string,
  message: PropTypes.string,
  messageColor: PropTypes.string,
  url: PropTypes.string,
  stateBackground: PropTypes.string,
};

import React, { Component } from "react";
import "./Intro.css";
import "./index.css";
import gsap from "gsap";
import { Howl } from "howler";

export class Intro extends Component {
  startQuiz() {
    this.props.startQuizSession();
  }

  constructor(props) {
    super(props);
    this.quizRef = React.createRef();
    this.contentWallRef = React.createRef();

    this.sound = new Howl({
      src: ["/pop-up-off.mp3"],
    });
  }

  handlePlaySound() {
    this.sound.play();
  }

  componentDidMount() {
    if ("intro_state" !== this.props.appState) return;
    setTimeout(() => {
      gsap.to(".App", {
        opacity: 1,
        duration: 0.3,
        ease: "power2.out",
      });

      gsap.to(".start-btn-img,.start-btn", {
        bottom: 20,
        opacity: 1,
        ease: "power2.out",
        onComplete: () => {
          setTimeout(() => {
            gsap.to(".start-btn-img,.start-btn", {
              scale: 1.04,
              repeat: -1,
              yoyo: true,
            });
          }, 5000);
        },
      });
      gsap.to("#valo-btn", {
        bottom: 20,
        delay: 0.15,
        opacity: 1,
        ease: "power2.out",
      });
      gsap.to(".tuto > p", {
        y: 20,
        opacity: 0,
      });
      gsap.to(".tuto > img", {
        x: -20,
        opacity: 0,
      });
      gsap.to(".tuto-header > img", {
        scale: 0,
        opacity: 0,
        delay: 0.5,
        duration: 1,
        ease: "elastic.out(1,0.3)",
      });

      gsap.to(".close-tutorial-btn", {
        duration: 2.5,
        ease: "elastic.out(1,0.3)",
        y: -10,
        opacity: 0,
        delay: 0.1,
      });

    }, 1000);
  }

  render() {
    const { introButtonLabel, introButtonImage } = this.props;

    const handleClick = () => {
      gsap.to(".quiz-overlay", {
        left: "auto",
        ease: "power2.out",
      });
      gsap.to(".start-btn-img,.start-btn", {
        bottom: -40,
        opacity: 0,
        ease: "power2.out",
        onComplete: () => {
          setTimeout(() => {
            gsap.to(".start-btn-img,.start-btn", {
              scale: 1.04,
              repeat: -1,
              yoyo: true,
            });
          }, 5000);
        },
      });
      gsap.to("#valo-btn", {
        bottom: -40,
        delay: 0.15,
        opacity: 0,
        ease: "power2.out",
      });
      gsap.to(".overlay", {
        right: 0,
        left: "auto",
        opacity: 1,
        ease: "power2.out",
        duration: 0.5,
        onComplete: () => {
          this.startQuiz();
        },
      });
    };

    return (
      <div className={"intro"}>
        <button
          className={"start-btn"}
          style={
            introButtonImage
              ? {
                  padding: 0,
                }
              : {}
          }
          onClick={() => {
            // this.startQuiz();
            handleClick();
            this.handlePlaySound();
          }}
        >
          {introButtonImage ? (
            <img className="start-btn-img" src={introButtonImage.contentUrl} />
          ) : (
            introButtonLabel
          )}
        </button>
      </div>
    );
  }
}

export default Intro;
